import { Product, Control_Panel_Sizing, Cost } from "../generated/graphql";

import parseMoney, { formatMoney } from "./money-utils";
import { calculateFLA, calculateMOCPNew, calculateProductCost } from "./generate-configuration-query";
import { getPanelCost } from "./calculate-custom-controls";

export const quoteSumTotal = (products: Product[], sizings: Control_Panel_Sizing[] | undefined, costs: Cost[] | undefined, projected: string = "") => {
  let value = 0;

  if (!!products.length) {
    value = products.reduce((sum: number, p: Product) => {

      const fla = calculateFLA(p.configuration?.fla_460, p.voltage_override, p.configuration?.min_v_fla, p.configuration?.max_v_fla);

      // Calculate controls cost 
      let productMOCP = calculateMOCPNew({
          fla: fla,
          fanCount: p.configured_fan_count ?? 1,
          voltage: p.voltage_override ?? p.configuration?.voltage,
          mas_ec_plus: p.mas_ec_plus,
      });

      let controlsCost = null;
      if (projected === "") controlsCost = getPanelCost(p.configured_fan_count, productMOCP, sizings, p.mas_ec_plus, p.outdoor_rating);
      else if (projected === "all ec plus") controlsCost = getPanelCost(p.configured_fan_count, productMOCP, sizings, true, p.outdoor_rating);
      else if (projected === "all basic") controlsCost = getPanelCost(p.configured_fan_count, productMOCP, sizings, false, p.outdoor_rating);
      else controlsCost = getPanelCost(p.configured_fan_count, productMOCP, sizings, p.mas_ec_plus, p.outdoor_rating);

      let large_scale_pricing = p.large_scale_pricing;
      if (projected === "large scale pricing") large_scale_pricing = true;
      else if (projected === "normal scale pricing") large_scale_pricing = false;

      return sum + (p.configuration_quantity * calculateProductCost({
        fanCost: parseMoney(p.configuration?.price) ?? 0,
        fanCount: p.configured_fan_count ?? 1,
        controlPanelCost:
          controlsCost,
        bulkheadHeight: p.height ?? 0,
        bulkheadWidth: p.width ?? 0,
        costPerSQFT:
          parseMoney(
            costs?.find((c) => c.name === "Bulkhead_Cost_per_SQFT")
              ?.value
          ) ?? 0,
        miscFlatCost:
          parseMoney(
            costs?.find((c) => c.name === "Misc_Flat")?.value
          ) ?? 0,
        miscVariableCost:
          parseMoney(
            costs?.find((c) => c.name === "Misc_Variable:")?.value
          ) ?? 0,
        damperCost:
          parseMoney(
            costs?.find((c) => c.name === "Backdraft_Damper")?.value
          ) ?? 0,
        hasDampers: p.backdraft_dampers ?? false,
        hasTransducers: p.pressure_transducers ?? false,
        largeScalePricing: large_scale_pricing,
        discountedFanCost: p.discounted_fan_price,
      }));
    }, 0);
  }

  return formatMoney(value);
};

const quoteHelpers = {
  quoteSumTotal
};

export default quoteHelpers