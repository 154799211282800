import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, ButtonGroup, Col, Container, Input, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';

import { DebounceInput } from 'react-debounce-input';

import AddJobModal from '../components/AddJobModal';
import DeleteEntityModal from '../components/DeleteEntityModal';
import DuplicateJobModal from '../components/DuplicateJobModal';

import { formatDate } from '../helpers/date-utils';
import {
  Job,
  useGetJobsQuery,
  useUpdateJobMutation,
  useDeleteJobByIdMutation,
  GetJobsQuery,
  GetJobsDocument,
} from '../generated/graphql';
import MHTable from '../components/MHTable';

import { ReactComponent as UndoSvg } from '../assets/undo.svg';

const Jobs = () => {
  const [filter, setFilter] = useState('');

  const [duplicateJobId, setDuplicateJobId] = useState<number | null>(null);
  const [deleteJobId, setDeleteJobId] = useState<number | null>(null);

  const { loading, data, refetch } = useGetJobsQuery({
    variables: {
      order_by: {
        updated_on: 'desc'
      } as any,
      where: !filter
        ? undefined
        : { name: { _ilike: `%${filter}%` } }
    }
  });
  const [deleteJob, { loading: deleting }] = useDeleteJobByIdMutation();
  const [updateJob] = useUpdateJobMutation();

  // console.log("data",data);
  // console.log("error",error);

  const handleUpdate = (jobId: number, newData: { name: string, status: string }) => {
    updateJob({
      variables: { pk_columns: { id: jobId }, _set: { ...newData } },
      update: (cache, { data }) => {
        const existingData = cache.readQuery<GetJobsQuery>({
          query: GetJobsDocument,
        });
        const updated = data?.update_job_by_pk;
        if (!updated) return;
        cache.evict({
          // Often cache.evict will take an options.id property, but that's not necessary
          // when evicting from the ROOT_QUERY object, as we're doing here.
          fieldName: "job",
          // No need to trigger a broadcast here, since writeQuery will take care of that.
          broadcast: false,
        });

        const jobs = existingData?.job.map((j) => ({ ...j }));
        const target = jobs?.find((p) => p.id === updated.id);

        if (!target) return;

        target.name = (newData as any)?.name;
        target.status = (newData as any)?.status;

        cache.writeQuery({
          query: GetJobsDocument,
          data: {
            job: jobs
          },
        });
      }
    });
  };

  const onConfirmDelete = () => {
    if (!deleteJobId) return;

    deleteJob({
      variables: { id: deleteJobId! },
      update: (cache, { data }) => {
        const existingData = cache.readQuery<GetJobsQuery>({
          query: GetJobsDocument,
        });
        const deleted = data?.delete_job_by_pk;
        if (!deleted) return;
        cache.evict({
          // Often cache.evict will take an options.id property, but that's not necessary
          // when evicting from the ROOT_QUERY object, as we're doing here.
          fieldName: "job",
          // No need to trigger a broadcast here, since writeQuery will take care of that.
          broadcast: false,
        });

        cache.writeQuery({
          query: GetJobsDocument,
          data: {
            job: existingData?.job.filter(
              (p) => p.id !== deleted.id
            ),
          },
        });
      },
      onCompleted: () => setDeleteJobId(null),
    });
  };

  const onConfirmDuplicate = () => {
    if (!duplicateJobId) return;

    setDuplicateJobId(null);
    refetch();
  };

  const columnHelper = createColumnHelper<Job>();

  const table = useReactTable(
    {
      columns: [
        columnHelper.accessor('name', {
          header: () => <span>Name</span>,
        }),
        columnHelper.accessor('products_aggregate', {
          header: () => <span>Products</span>,
        }),
        columnHelper.accessor('quotes_aggregate', {
          header: () => <span>Quotes</span>,
        }),
        // columnHelper.display({
        //   id: 'owner',
        //   header: () => <span>Owner</span>,
        // }),
        columnHelper.accessor('created_on', {
          header: () => <span>Created On</span>,
        }),
        columnHelper.accessor('updated_on', {
          header: () => <span>Updated On</span>,
        }),
        columnHelper.display({
          id: 'actions',
        })
      ],
      data: data?.job as Job[] ?? [],
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
    }
  );

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  return (
    <div className="page">
      <Container className="mt-3">
        <DuplicateJobModal
          jobId={duplicateJobId}
          onCancel={() => setDuplicateJobId(null)}
          onConfirm={() => onConfirmDuplicate()}
        />
        <DeleteEntityModal
          entity='job'
          isOpen={!!deleteJobId}
          pending={deleting}
          onCancel={() => setDeleteJobId(null)}
          onConfirm={() => onConfirmDelete()}
        />
        <Row className="page-title d-flex mb-3">
          <Col>
            <h4 className="blink">Jobs</h4>
          </Col>
          <Col className="d-flex align-items-center">
            <DebounceInput
              className="mx-1 form-control"
              style={{ height: '40px' }}
              minLength={2}
              debounceTimeout={300}
              placeholder='Search by job name'
              value={filter}
              onChange={(e: any) => setFilter(e.target.value)}
            />
            <AddJobModal onSuccess={() => refetch()} />
          </Col>
        </Row>
        {loading
          ? <div>Loading</div>
          : (<MHTable
            table={table}
            mapRow={(row) => (
              <JobRow
                key={row.id}
                job={row.original}
                handleUpdate={handleUpdate}
                setDuplicateJobId={setDuplicateJobId}
                setDeleteJobId={setDeleteJobId}
              />
            )}
          />)}
      </Container>
    </div>
  );
};

const JobRow = ({
  job,
  handleUpdate,
  setDuplicateJobId,
  setDeleteJobId,
}: {
  job: Job,
  handleUpdate: (jobId: number, data: { name: string, status: string }) => void;
  setDuplicateJobId: Function;
  setDeleteJobId: Function;
}) => {
  const [editMode, setEditMode] = useState(false);

  const [data, setData] = useState({
    name: job.name,
    status: job.status,
  });

  const reset = () => {
    setEditMode(false);
    setData({
      name: job.name,
      status: job.status
    })
  };

  const onUpdate = () => {
    handleUpdate(job.id, data);
    setEditMode(false);
  };

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    setData({
      ...data,
      [e.target.name]: e.target.value
    });

  return (
    <tr>
      <td style={{ textAlign: 'left' }}>
        {
          !editMode
            ? (<NavLink to={`/jobs/${job.id}`}>{job.name}</NavLink>)
            : <Input name="name" type='text' value={data.name} onChange={onInput} />
        }
      </td>
      <td>
        {job.products_aggregate.aggregate?.count}
      </td>
      <td>
        {job.quotes_aggregate.aggregate?.count}
      </td>
      {/* <td>
        <div>Unknown (Not hooked up yet!)</div>
      </td> */}
      <td>
        {formatDate(job.created_on)}
      </td>
      <td>
        {formatDate(job.updated_on)}
      </td>
      <td>
        <div className="d-flex justify-content-around">
          {!editMode
            ? (
              <ButtonGroup>
                <Button onClick={() => setEditMode(true)}><FontAwesomeIcon icon='pencil'></FontAwesomeIcon></Button>
                <Button onClick={() => setDuplicateJobId(job.id!)}><FontAwesomeIcon icon='clone' /></Button>
                <Button onClick={() => setDeleteJobId(job.id!)}><FontAwesomeIcon icon='trash' /></Button>
              </ButtonGroup>
            )
            : (
              <ButtonGroup>
                <Button onClick={() => reset()}><UndoSvg /></Button>
                <Button type='button' disabled={!data.name} onClick={() => onUpdate()}><FontAwesomeIcon icon='save' /></Button>
              </ButtonGroup>
            )
          }
        </div>
      </td>
    </tr>
  );
};

export default Jobs;