import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Quote, useInsertQuoteMutation } from '../generated/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

const DuplicateQuoteModal = ({
  quote,
  isOpen,
  toggle,
  onSuccess,
}: {
  jobId: number;
  quote: Quote | null;
  isOpen: boolean;
  toggle: Function;
  onSuccess?: Function;
}) => {
  const [quoteName, setQuoteName] = useState(`Copy of ${quote?.name}`);

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => setQuoteName(e.target.value);

  const [createQuote, { loading: creating }] = useInsertQuoteMutation();

  const handleDuplicate = useCallback(() => {
    const variables = {
      object: {
        name: quoteName,
        status: quote?.status,
        job_id: quote?.job_id,
        products: { data: [] as any[] }
      }
    };

    if (!!quote?.products.length) {
      variables.object.products.data = quote.products.map((p) => ({
        ...p,
        id: undefined,
        quote_id: undefined,
        updated_at: undefined,
        created_at: undefined,
        __typename: undefined,
        configuration: undefined,
        has_serial_number: undefined,
      } as any));
    }

    createQuote({
      variables,
      onCompleted: () => !!onSuccess ? onSuccess() : () => {}
    });
  }, [quote, quoteName, createQuote, onSuccess]);

  useEffect(() => {
    setQuoteName(`Copy of ${quote?.name}`);
  }, [quote]);

  return (
    <Modal isOpen={isOpen} toggle={() => toggle()}>
      <div className="modal-header">
        <h3>Duplicate quote</h3>
        <FontAwesomeIcon
          style={{ fontSize: 20, cursor: "pointer", marginLeft: "5px" }}
          onClick={() => { toggle(); }}
          icon={faXmarkCircle} />
      </div>
      <ModalBody>
        <div className="d-flex flex-column mb-3">
          <label>Name</label>
          <Input placeholder={`Copy of ${quote?.name}`} value={quoteName} onChange={onInput} />
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-start mx-2">
        <Button disabled={!quoteName || creating} className='mt-3' onClick={handleDuplicate}>Duplicate</Button>
      </ModalFooter>
    </Modal>
  );
};

export default DuplicateQuoteModal;