import React, { useState } from "react";
import {
  Button, Modal,
} from "reactstrap";
import { QueryResult } from "@apollo/client";
import "./Quotes.css";

import {
  Control_Panel_Sizing,
  Cost,
  Exact,
  Fan_Fit,
  GetProductsDocument,
  GetProductsQuery,
  GetQuotesByJobIdDocument,
  GetQuotesByJobIdQuery,
  Job,
  Product,
  Quote,
  useDeleteQuoteMutation,
  useInsertQuoteMutation,
} from "../generated/graphql";

import DuplicateQuoteModal from "./DuplicateQuoteModal";
import DeleteEntityModal from "./DeleteEntityModal";
import ExpandedConfigurationDetails from "./ExpandedConfigurationDetails";

import QuoteComponent from "./Quote";
import UpdateJobSettings from "./UpdateJobSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { SaleStatus } from "../types";

const QuotesTable = ({
  job,
  quotesQuery,
}: {
  job: Job;
  quotesQuery: QueryResult<GetQuotesByJobIdQuery, Exact<{ jobId: number }>>;
}) => {
  const { loading, data, refetch } = quotesQuery;
  const jobId = job.id;

  const [duplicateQuote, setDuplicateQuote] = useState<Quote | null>(null);
  const [deleteQuoteId, setDeleteQuoteId] = useState<number | null>(null);
  const [showJobSettingsModal, setShowJobSettingsModal] = useState<boolean>(false);
  const [expandedDetails] = useState<{
    product: Product;
    fanFit?: Fan_Fit;
    sizing?: Control_Panel_Sizing;
    costs?: Cost[];
  } | null>(null);

  const [createQuote] = useInsertQuoteMutation();

  const newQuote = () => {
    createQuote({
      variables: { object: { job_id: jobId, name: "MAS EC Fan Array", status: SaleStatus.Open } },
      update: (cache, { data }) => {
        const existing = cache.readQuery<GetQuotesByJobIdQuery>({
          query: GetQuotesByJobIdDocument,
        });
        const inserted = data?.insert_quote_one;
        if (!inserted) return;
        cache.writeQuery({
          query: GetQuotesByJobIdDocument,
          data: {
            quote: existing?.quote.concat([{ ...inserted, products: [] }]),
          },
        });
      },
      onCompleted: (data) => {
        refetch();
      }
    });
  };

  const [deleteQuote, { loading: deleting }] = useDeleteQuoteMutation();

  const onConfirmDelete = () => {
    if (!deleteQuoteId) return;

    deleteQuote({
      variables: { id: deleteQuoteId! },
      update: (cache, { data }) => {
        const existingData = cache.readQuery<GetQuotesByJobIdQuery>({
          query: GetQuotesByJobIdDocument,
          variables: { jobId: jobId },
        });

        const deleted = data?.delete_quote_by_pk;
        if (!deleted) return;
        cache.evict({
          // Often cache.evict will take an options.id property, but that's not necessary
          // when evicting from the ROOT_QUERY object, as we're doing here.
          fieldName: "quote",
          // No need to trigger a broadcast here, since writeQuery will take care of that.
          broadcast: false,
        });

        cache.writeQuery({
          query: GetQuotesByJobIdDocument,
          data: {
            quote: existingData?.quote.filter((p) => p.id !== deleted.id),
          },
        });
        const newlyUnquotedProducts = existingData?.quote
          .find((p) => p.id === deleted.id)
          ?.products.map((p) => ({ ...p, quote_id: null }));

        if (!newlyUnquotedProducts) return;
        const existingProductsData = cache.readQuery<GetProductsQuery>({
          query: GetProductsDocument,
          variables: {
            where: {
              job_id: { _eq: jobId },
              quote_id: {
                _is_null: true,
              },
            },
          },
        });

        if (!existingProductsData) return;
        cache.writeQuery({
          query: GetProductsDocument,
          variables: {
            where: {
              job_id: { _eq: jobId },
              quote_id: {
                _is_null: true,
              },
            },
          },
          data: {
            product: existingProductsData?.product.concat(
              newlyUnquotedProducts
            ),
          },
        });
      },
      onCompleted: () => setDeleteQuoteId(null),
    });
  };

  let quotes = ((data?.quote.slice() as Quote[]) || []);
  quotes.sort((a: Quote, b: Quote) => a.id > b.id ? 1 : -1);
  return (
    <>
      <div className="job-actions">
        <h2>{job.name}</h2>
        <button 
          className="basic" 
          onClick={() => { setShowJobSettingsModal(true); }}
        >
          <FontAwesomeIcon icon={faCog} size="sm" />
        </button>
        <Button 
          color="primary" 
          outline 
          onClick={() => newQuote()}
        >Add Quote</Button>
        <DuplicateQuoteModal
          jobId={jobId}
          quote={duplicateQuote}
          isOpen={!!duplicateQuote}
          toggle={() => setDuplicateQuote(null)}
          onSuccess={() => {
            setDuplicateQuote(null);
            refetch();
          }}
        />
        <DeleteEntityModal
          entity="quote"
          isOpen={!!deleteQuoteId}
          pending={deleting}
          onCancel={() => setDeleteQuoteId(null)}
          onConfirm={() => onConfirmDelete()}
        />
        {!!expandedDetails && (
          <ExpandedConfigurationDetails
            modal
            product={expandedDetails.product}
            configuration={expandedDetails.product.configuration!}
            fanCount={expandedDetails.product.configured_fan_count!}
            fanFit={expandedDetails.fanFit!}
            sizing={expandedDetails.sizing!}
            costs={expandedDetails.costs!}
            expandedValid={true}
          />
        )}
      </div>
      <div className="job-quotes-container">
        {
          loading ? (
            <div>Loading</div>
          ) : (
            <div className="job-quotes">
              {quotes.length > 0 ? quotes.map((quote) => {
                return <QuoteComponent
                  job={job}
                  quote={quote}
                  setDeleteQuoteId={setDeleteQuoteId}
                  setDuplicateQuote={setDuplicateQuote}
                  refetch={() => {
                    refetch();
                  }}
                  key={quote.id} />
              }) : <div>
                <div className="empty-quote single">
                  <button onClick={() => newQuote()}>+ Add Quote</button>
                </div>
              </div>}
            </div>
          )}
      </div >
      {
        showJobSettingsModal &&
        (<Modal isOpen={showJobSettingsModal}
          toggle={() => setShowJobSettingsModal(false)}
          autoFocus={false}>
          <UpdateJobSettings
            setIsOpen={() => setShowJobSettingsModal(false)}
            job={job}
            refetch={refetch}
            key={job.id} />
        </Modal>)
      }
    </>
  );
};

export default QuotesTable;
