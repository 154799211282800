import { Configuration, Control_Panel_Sizing } from "../generated/graphql";
import { DesignParameters } from "./fan-curve";

interface Config {
    configuration: Configuration,
    designIteration: DesignParameters,
    fla: number,
    mca: number,
    mocp: number,
    inputPower: number,
    efficiency: number,
    redundancy: number,
    inletSound: number,
    outletSound: number,
    sizing?: Control_Panel_Sizing | undefined,
    unitCost?: number,
    arrayWeight?: number,
}

export interface ConfigFilter {
    fanManufacturer?: string,
    fanModel?: string,
    efficiencyMax?: number,
    flaMax?: number,
    redundancyMax?: number,
    mcaMax?: number,
    mocpMax?: number,
    motorPowerMax?: number,
    inletSoundMax?: number,
    outletSoundMax?: number,
    unitCostMax?: number,
    fanCountMax?: number,
    operatingSpeedMax?: number,
    maximumSpeedMax?: number,
    systemWeightMax?: number,
    fanWeightMax?: number,
    controlPanelWidthMax?: number,
    efficiencyMin?: number,
    flaMin?: number,
    redundancyMin?: number,
    mcaMin?: number,
    mocpMin?: number,
    motorPowerMin?: number,
    inletSoundMin?: number,
    outletSoundMin?: number,
    unitCostMin?: number,
    fanCountMin?: number,
    operatingSpeedMin?: number,
    maximumSpeedMin?: number,
    systemWeightMin?: number,
    fanWeightMin?: number,
    controlPanelWidthMin?: number,
}

// definition for filter sections
export const shownFilters= [
    {
        name: "Efficiency", // name displayed on filter
        configName: "efficiency", // name for js recognition
        checked: false, // used in js to toggle whether filter is shown
        placeholder: "%", // placeholder for inputs
        minimum: "efficiencyMin", // name for minimum input
        maximum: "efficiencyMax", // name for maximum input
        limitMaxMinBoth: "min", // which field should show up/be filtered on
    },
    {
        name: "Redundancy", 
        configName: "redundancy", 
        checked: false, 
        placeholder: "%", 
        minimum: "redundancyMin", 
        maximum: "redundancyMax",
        limitMaxMinBoth: "min",
    },
    {
        name: "System Weight", 
        configName: "systemWeight", 
        checked: false, 
        placeholder: "lbs", 
        minimum: "systemWeightMin", 
        maximum: "systemWeightMax",
        limitMaxMinBoth: "max",
    },
    {
        name: "Price", 
        configName: "unitCost", 
        checked: false, 
        placeholder: "$", 
        minimum: "unitCostMin", 
        maximum: "unitCostMax",
        limitMaxMinBoth: "max",
    },
    {
        name: "MOCP", 
        configName: "mocp", 
        checked: false, 
        placeholder: "A", 
        minimum: "mocpMin", 
        maximum: "mocpMax",
        limitMaxMinBoth: "max",
    },
    {
        name: "Supply Sound Power", 
        configName: "outletSound", 
        checked: false, 
        placeholder: "dB", 
        minimum: "outletSoundMin", 
        maximum: "outletSoundMax",
        limitMaxMinBoth: "max",
    },
    {
        name: "Return Sound Power", 
        configName: "inletSound", 
        checked: false, 
        placeholder: "dB", 
        minimum: "inletSoundMin", 
        maximum: "inletSoundMax",
        limitMaxMinBoth: "max",
    },
];

/* Custom filter should return:
    - configs that have values that fit between the max and min for all non-null elements
    - configs that have a model that is in the fanModel
*/
export const customFilter = (active: boolean, activeFilters : typeof shownFilters, filters : ConfigFilter) => {
    return function(c: Config) {
        if (active){
            // Get set of active filters so we know which ones to use
            let activeFiltersSet = new Set();
            activeFilters.forEach((filter) => {
                if(filter.checked) activeFiltersSet.add(filter.configName); 
            });
            // filter for each property
            if (filters?.fanManufacturer && c.configuration.manufacturer.toUpperCase() !== filters.fanManufacturer.toUpperCase()) return false;
            else if (activeFiltersSet.has("unitCost") && filters?.unitCostMax && c.unitCost > filters.unitCostMax) return false;
            else if (activeFiltersSet.has("mocp") && filters?.mocpMax && c.mocp > filters.mocpMax) return false;
            else if (activeFiltersSet.has("systemWeight") && filters?.systemWeightMax && c.arrayWeight > filters.systemWeightMax) return false;
            else if (activeFiltersSet.has("redundancy") && filters?.redundancyMin && c.redundancy * 100 < filters.redundancyMin) return false;
            else if (activeFiltersSet.has("outletSound") && filters?.outletSoundMax && c.outletSound > filters.outletSoundMax) return false;
            else if (activeFiltersSet.has("inletSound") && filters?.inletSoundMax && c.inletSound > filters.inletSoundMax) return false;
            else if (activeFiltersSet.has("efficiency") && filters?.efficiencyMin && c.efficiency * 100 < filters.efficiencyMin) return false;
            return true;
        } else {
            return true;
        }
    }
}


/* Declutter filter should return:
    - only first 10 configs returned maximum
    - configs with 2 lowest amounts per model
        this was previous behavior (I've left the lines commented out for now):
    - configs with 3 lowest amounts per model
    - configs with 1 lowest amount per model for Rosenburg fans
*/ 
export const declutterFilter = (active: boolean, show_infinitum: boolean, lowestFanAmts: Record<string, number>) => {
    return function(c: Config) {
        if (active){
            if (c?.configuration?.model === undefined || c?.configuration?.manufacturer === undefined || c?.sizing?.fan_count === undefined) return false;
            else if (c.sizing.fan_count > (lowestFanAmts[c.configuration.model] + 1)) return false;
            else if (!show_infinitum && c.configuration.manufacturer === "Peerless Blowers") return false;
            return true;
        } else if (!active && !show_infinitum) {
            if (c?.configuration?.model === undefined || c?.configuration?.manufacturer === undefined || c?.sizing?.fan_count === undefined) return false;
            else if (c.configuration.manufacturer === "Peerless Blowers") return false;
            return true;
        } else {
            return true;
        }
    }
};

export const minFansPerModel = (configs : Config[]) => {
    const lowestFanAmts : Record<string, number> = {};
    // get lowest amount of fans per each model
    for (let config of configs){
        if (config?.configuration?.model !== undefined && config?.sizing?.fan_count !== undefined){
            if(!lowestFanAmts.hasOwnProperty(config.configuration.model)){
                lowestFanAmts[config.configuration.model] = config.sizing.fan_count;
            } else if (config.sizing.fan_count < lowestFanAmts[config.configuration.model]) {
                lowestFanAmts[config.configuration.model] = config.sizing.fan_count
            }
        }
    }
    return lowestFanAmts;
}

const configFilters = {
    declutterFilter,
    minFansPerModel,
    customFilter
};
  
export default configFilters;