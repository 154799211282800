import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, FormGroup, Input, Modal, ModalBody, ModalFooter } from "reactstrap";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Product, Serial_Number, useUpdateQuoteMutation, useUpdateSerialNumberMutation } from "../generated/graphql";
import { faSpinner } from '@fortawesome/free-solid-svg-icons'


const AddFinalInfoModal = ({
    quote,
    isOpen = true,
    toggle,
    onSuccess,
}) => {
    // whole section states ---
    const [isLoading, setIsLoading] = useState(false);
    const [updateQuote] = useUpdateQuoteMutation();
    const [updateSerialNumber] = useUpdateSerialNumberMutation();
    const [anyChanges, updateAnyChanges] = useState(false);

    // per all serials input states ---
    const [finalInfoInput, updateFinalInfoInput] = useState({
        firmware: "",
        software: ""
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value: any = "";
        value = e.target.value;
    
        updateFinalInfoInput({ ...finalInfoInput, [e.target.name]: value });

        updateAnyChanges(true);
    };

    // per serial input states ---

    const returnEmptySerialArrays = () => {
        return quote?.products.map(
            (p : Product) => {return p.has_serial_number.map(
                (s : Serial_Number) => {
                    return {unit_tag: p.tag}
                }
            )}
        )
    }
    const [serialInputs, updateSerialInputs] = useState(returnEmptySerialArrays);

    const handlePerSerialChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value: any = "";
        value = e.target.value;

        let serialIdx = e.target.attributes["data-serialindex"].value;
        let productIdx = e.target.attributes["data-productindex"].value;

        let tempSerialInputs = [...serialInputs];
        let serialInput = {...tempSerialInputs[productIdx][serialIdx]};
        serialInput[e.target.name] = value;
        tempSerialInputs[productIdx][serialIdx] = serialInput;
        updateSerialInputs(tempSerialInputs);

        updateAnyChanges(true);
    };

    const hasECPlusControls = quote?.products.find((p : Product) => p.mas_ec_plus);

    // validation block ---
    function checkSerialsValid() {
        for (const productInput of serialInputs){
            for(const serialInput of productInput){
                if (serialInput.unit_tag === "") return false;
            }
        }
        return true;
    }

    const validSoftware = finalInfoInput.software !== "";
    const validFirmware = finalInfoInput.firmware !== "";
    const isValid = (validSoftware && validFirmware && checkSerialsValid());

    // load as empty or set data
    useEffect(() => {
        if(!isOpen) {
            updateFinalInfoInput({
                firmware: quote.firmware,
                software: quote.software
            });
            updateSerialInputs(quote?.products.map(
                (p : Product) => {return p.has_serial_number.map(
                    () => {
                        return {unit_tag: p.tag}
                    }
                )}
            ))
            updateAnyChanges(false);
        } else {
            // Update firmware & software
            if(!hasECPlusControls){
                updateFinalInfoInput({
                    firmware: "NONE (BASIC CONTROLS)",
                    software: "NONE (BASIC CONTROLS)"
                });
            } else if (quote.firmware === "" && quote.software === ""){
                updateFinalInfoInput({
                    firmware: "",
                    software: ""
                });
            } else {
                updateFinalInfoInput({
                    firmware: quote.firmware,
                    software: quote.software
                });
            }
            // Update serial info
            updateSerialInputs(quote?.products.map(
                (p : Product) => {return p.has_serial_number.map(
                    (s : Serial_Number) => {
                        return {unit_tag: s.unit_tag ?? p.tag}
                    }
                )}
            ))
        }
    },[hasECPlusControls, isOpen, quote]);
    
    let serialIndex = -1;
    let productIndex = -1;

    // submit section ---
    let totalSerials = 0;
    for(const product of quote?.products){
        totalSerials += product.has_serial_number.length;
    }

    const onSubmitForm = (e: FormEvent<HTMLFormElement>) => {
        if (!anyChanges){
            onSuccess();
        } else {
            setIsLoading(true);
            // Collect info from form
            let newFirmware: string, newSoftware: string;
            let serialInfoHolder = [];
            // There are 2 fixed fields, and then 2 fields per serial
            for (let idx = 0; idx < 2 + (totalSerials * 2); idx++){
                // first two fields are firmware & software
                if (idx === 0) newFirmware = e.target[idx].value;
                else if (idx === 1) newSoftware = e.target[idx].value;
                // then we have n*2 fields containing serial info, so add them in order: id, unit_tag
                else if (idx >= 2 && e.target[idx].type !== "button") serialInfoHolder.push(e.target[idx].value);
            }
    
            // Update product
            let allPromises = [];
            allPromises.push(updateQuote({
                variables: {
                  pk_columns: { id: quote.id },
                  _set: { software : newSoftware, firmware : newFirmware },
                },
            }));
    
            // Update serial numbers
            // Access 2 at a time, since serial info is stored in holder array as (id, drc_job_number)*n
            for (let idx = 0; idx < serialInfoHolder.length; idx += 2){
                allPromises.push(updateSerialNumber({
                    variables: {
                        pk_columns: { id: serialInfoHolder[idx]},
                        _set: { unit_tag: serialInfoHolder[idx+1]},
                    },
                }));
            }
    
            // Once all queries are done
            Promise.all(allPromises).then((e) => {
                setIsLoading(false);
                onSuccess();
            });
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} toggle={() => toggle()} backdrop={"static"} size="lg">
                <Form onSubmit={(e) => { e.preventDefault(); onSubmitForm(e) }}>
                    <div className="modal-header">
                        <h3>Order Information</h3>
                        <FontAwesomeIcon
                        style={{ fontSize: 20, cursor: "pointer", marginLeft: "5px" }}
                        onClick={() => { if (!isLoading) toggle(); }}
                        icon={faXmarkCircle} />
                    </div>
                    <ModalBody>
                        <div className="input-holder">
                            <div className="box-label" style={!hasECPlusControls ? {display:"none"} : {}}>Firmware Version</div>
                            <FormGroup style={!hasECPlusControls ? {display:"none"} : {}}>
                                <Input
                                    name="firmware"
                                    type="text"
                                    value={finalInfoInput?.firmware || ""}
                                    onChange={handleChange}
                                    invalid={!validFirmware}
                                />
                            </FormGroup>
                            <div className="box-label" style={!hasECPlusControls ? {display:"none"} : {}}>Software Version</div>
                            <FormGroup style={!hasECPlusControls ? {display:"none"} : {}}>
                                <Input
                                    name="software"
                                    type="text"
                                    value={finalInfoInput?.software || ""}
                                    onChange={handleChange}
                                    invalid={!validSoftware}
                                />
                            </FormGroup>
                            {
                                (quote.products || []).map((p: Product) => {
                                    productIndex++;
                                    serialIndex = -1;
                                    return(<div className="serial-holder" key={`product-${p.id}`}>
                                        <div className="serial-title-box">
                                            <span className="tag-title">{p.tag}</span>
                                        </div>
                                        <table className="serial-table">
                                            <thead>
                                                <tr>
                                                    <th>Serial Number</th>
                                                    <th>Unit Tag</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                (p.has_serial_number || []).map((s: Serial_Number) => {
                                                    serialIndex++;
                                                    return(<NewFinalInfoSerial
                                                        serial={s}
                                                        serialInput={serialInputs[productIndex][serialIndex]}
                                                        productIndex={productIndex}
                                                        serialIndex={serialIndex}
                                                        handleChange={handlePerSerialChange}
                                                        isLoading={isLoading}
                                                        key={`serial-${s.id}`}
                                                    />)
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>);
                                })
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-start mx-2">
                        <Button disabled={isLoading || !isValid} type='submit'>
                            Submit
                            {isLoading && <FontAwesomeIcon icon={faSpinner} spin/>}
                        </Button>
                    </ModalFooter>
                </Form>
        </Modal>
    </>);
};

function NewFinalInfoSerial ({serial, serialInput, productIndex, serialIndex, handleChange, isLoading}){

    const unit_tag_valid = !!serialInput?.unit_tag;

    return(
        <>
            <tr>
                <td>{serial.serial_number}</td>
                <td style={{display:"none"}}>
                    <Input type="text" name="id" value={serial.id} readOnly/>
                </td>
                <td>
                    <Input 
                        type="text" 
                        name="unit_tag" 
                        value={serialInput?.unit_tag || ""} 
                        onChange={handleChange} 
                        invalid={!unit_tag_valid}
                        data-productindex={productIndex}
                        data-serialindex={serialIndex}
                        disabled={isLoading}
                    />
                </td>
            </tr>
        </>
    );
}

export default AddFinalInfoModal;