import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom";
import { useGetControlPanelSizingQuery, Quote, Product, useInsertProductMutation, useUpdateProductMutation, useGetCostsQuery, Configuration, Fan_Fit, useGetFanModelFileLazyQuery, useGetFanFitsQuery, Control_Panel_Sizing, useGetSerialNumbersForJobQuery } from "../generated/graphql";
import { calculateFLA, calculateMOCPNew, getCostBreakdown } from "../helpers/generate-configuration-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generateQuoteDXF } from '../helpers/generate-quote.dxf';
import parseMoney from "../helpers/money-utils";
import "./QuoteProduct.css"
import UpdateQuantityModal from "./UpdateQuantityModal";
import { toast } from 'react-toastify';
import { Modal } from "reactstrap";
import { faFileArrowDown, faPenToSquare, faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { CostBreakdownTooltip } from "./CostBreakdownTooltip";
import { getPanelCost } from "../helpers/calculate-custom-controls";
import AddControlsInfoModal from "./AddControlsInfoModal";

const QuoteProduct = ({ 
    quote, 
    jobId, 
    locked, 
    refetch,
    finalLock
}: { 
    quote: Quote, 
    jobId: number, 
    locked: boolean, 
    refetch: () => void,
    finalLock: boolean
}) => {
    const [insertProduct] = useInsertProductMutation();
    const [updateProduct] = useUpdateProductMutation();
    const [updateQuantityProduct, setUpdateQuantityProduct] = useState<Product>();
    const [showAddControlsInfoModal, updateShowAddControlsInfoModal] = useState(false);
    const [addControlsToProduct, updateAddControlsToProduct] = useState<Product>();
    const [getFanModelFile] = useGetFanModelFileLazyQuery();
    const { data: fanFitData } = useGetFanFitsQuery();
    const { data: costs } = useGetCostsQuery();
    const { data: sizing } = useGetControlPanelSizingQuery();

    const onDuplicate = (product: Product) => {
        const newProduct = {
            ...product,
            configuration_quantity: 1,
            id: undefined,
            updated_at: undefined,
            created_at: undefined,
            configuration: undefined,
            __typename: undefined,
        };

        newProduct.has_serial_number = null;

        insertProduct({
            variables: {
                object: { ...newProduct } as any,
            },
            onCompleted: () => refetch(),
        });
    };

    const onRemove = (product: Product) => {
        updateProduct({
            variables: {
                pk_columns: { id: product.id },
                _set: { quote_id: null, configuration_quantity: 1 },
            },
            onCompleted: () => refetch(),
        });
    };

    const requestDXF = async ({
        fileName,
        config,
        fanFitData,
        fanCount,
        products,
        sizings,
    }: {
        fileName: string,
        config: Configuration,
        fanFitData?: Fan_Fit[],
        fanCount: number,
        products: Product[],
        sizings: Control_Panel_Sizing[],
    }) => {
        let model = config.model;
        let getFMFRequest = await getFanModelFile({ variables: { model } });
        let fan_model_file = !!getFMFRequest.data?.fan_model_file.length
            ? getFMFRequest.data.fan_model_file[0]
            : undefined;

        generateQuoteDXF({
            config: config,
            fanFitData,
            fanModelFile: fan_model_file,
            fileName: fileName,
            products: products,
            sizings: sizings
        }).catch((e) => toast.error(e.message))
    };

    const { data : serial_numbers, refetch : refetchSerials } = useGetSerialNumbersForJobQuery({variables : {job_id: jobId}});
    // Need to refetch serials when job is locked or it won't display until page refresh
    useEffect(() => {
        refetchSerials();
    }, [showAddControlsInfoModal, refetchSerials]);

    return (
        <div className="quote-product-list" key={quote.id}>
            {(quote.products || []).map((product) => {
                const fla = calculateFLA(product?.configuration?.fla_460, product.voltage_override, product?.configuration?.min_v_fla, product?.configuration?.max_v_fla);
                
                // Calculate controls cost 
                let productMOCP = calculateMOCPNew({
                    fla: fla,
                    fanCount: product.configured_fan_count ?? 1,
                    voltage: product.voltage_override ?? product?.configuration?.voltage,
                    mas_ec_plus: product.mas_ec_plus,
                });
                const controlsCost = getPanelCost(product.configured_fan_count, productMOCP, sizing?.control_panel_sizing, product.mas_ec_plus, product.outdoor_rating);

                // const quantity = product.configuration_quantity ?? 1;
                const costBreakdown = getCostBreakdown({
                    fanCost: parseMoney(product?.configuration?.price) ?? 0,
                    fanCount: product.configured_fan_count ?? 1,
                    controlPanelCost: controlsCost,
                    bulkheadHeight: product.height ?? 0,
                    bulkheadWidth: product.width ?? 0,
                    costPerSQFT:
                        parseMoney(
                            costs?.cost.find((c) => c.name === "Bulkhead_Cost_per_SQFT")?.value
                        ) ?? 0,
                    miscFlatCost:
                        parseMoney(costs?.cost.find((c) => c.name === "Misc_Flat")?.value) ?? 0,
                    miscVariableCost:
                        parseMoney(costs?.cost.find((c) => c.name === "Misc_Variable:")?.value) ??
                        0,
                    hasTransducers: product.pressure_transducers ?? false,
                    damperCost:
                        parseMoney(costs?.cost.find((c) => c.name === "Backdraft_Damper")?.value) ??
                        250,
                    hasDampers: product.backdraft_dampers ?? false,
                    largeScalePricing: product.large_scale_pricing,
                    discountedFanCost: product.discounted_fan_price
                });
                const unitCost = costBreakdown.totalCost;

                // const total = quantity * unitCost;

                const optionLabels = []
                if (product.backdraft_dampers)
                    optionLabels.push({label: "Backdraft dampers", color: "#ececec"});
                if (product.over_one_hundred_percent_redundancy)
                    optionLabels.push({label: "N+1", color: "#ececec"});
                if (product.pressure_transducers)
                    optionLabels.push({label: "Transducers", color: "#ececec"});
                if (product.mas_ec_plus && !product.outdoor_rating) optionLabels.push({label: "Indoor", color: "#ececec"});
                else optionLabels.push({label: "Outdoor", color: "#ececec"});

                return (
                    <div className="quote-product" key={product.id}>
                        <table>
                            <tbody>
                                <tr className="system-header">
                                    <td colSpan={2}>
                                        {!locked ?
                                            <NavLink className="product-link" to={`/jobs/${jobId}/products/${product.id}/configurations`}>{product.tag}</NavLink>
                                            : <span className="product-link-disabled">{product.tag}</span>
                                        }
                                    </td>
                                    <td className="product-actions">
                                        {product.configuration && <button
                                            onClick={() => {
                                                requestDXF({
                                                    // fileName: `${jobName} - ${quote.name} - MAS EC Quote.dxf`,
                                                    fileName: `${product.tag}.dxf`,
                                                    config: product.configuration!,
                                                    fanFitData: fanFitData?.fan_fit,
                                                    fanCount: product.configured_fan_count || 0,
                                                    products: [product],
                                                    sizings: sizing?.control_panel_sizing,
                                                })
                                            }}
                                            title="Download drawing"
                                        >
                                            <FontAwesomeIcon icon={faFileArrowDown} />
                                        </button>}
                                        <button
                                            onClick={() => onRemove(product)}
                                            title="Delete product"
                                            disabled={locked}
                                        >
                                            <FontAwesomeIcon icon="trash" />
                                        </button>
                                        <button
                                            title="Clone product"
                                            onClick={() => onDuplicate(product)}
                                            disabled={locked}
                                        >
                                            <FontAwesomeIcon icon="clone" />
                                        </button>
                                    </td>
                                </tr>
                                <tr className="system-info">
                                    <td>{product.air_flow} (CFM), {product.total_static_pressure.toFixed(2)} (inWc)</td>
                                    <td>{product.width}"(W) x {product.height}"(H)</td>
                                    <td className="quantity-cell">
                                        <span className="quantity">Qty:</span>
                                        {!locked ? 
                                            <span className="quantity-value"
                                                onClick={() => setUpdateQuantityProduct(product)}>
                                                {product.configuration_quantity}
                                            </span>
                                            : <div className="quantity-value-disabled">{product.configuration_quantity}</div>
                                        }
                                    </td>
                                </tr>
                                {
                                    product.configuration && (
                                        <tr className="selected-configuration">
                                            <td colSpan={1}>{product.configured_fan_count} - {product.configuration.model}</td>
                                            <td>{product.mas_ec_plus ? "EC+ Controls" : "Basic controls"}</td>
                                            <td style={{ textAlign: "right", fontWeight: 600 }}>
                                                <CostBreakdownTooltip unitCost={costBreakdown} fontSize={12} id={`cost-breakdown-${product.id}`} />{" "}
                                                <span className={costBreakdown.largeScalePricing ? "highlighted" : ""}>
                                                    {unitCost.toLocaleString(undefined, {
                                                        style: "currency",
                                                        currency: "USD",
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0
                                                    })}
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        <div className="bottom-section">
                            <div className={locked ? "option-labels-small" : ""}>
                                {optionLabels.map(labelInfo => <span key={labelInfo.label} className="option-label" style={{ backgroundColor: labelInfo.color}}>{labelInfo.label}</span>)}
                            </div>
                            { locked && 
                                <div className="transmitted-info">
                                    <button className="quote-button" onClick={
                                        () => {
                                            updateShowAddControlsInfoModal(true);
                                            updateAddControlsToProduct(product);
                                        }
                                    }>
                                        { product?.model_number === null && <FontAwesomeIcon icon={faPenToSquare} />}
                                        { product?.model_number !== null && <FontAwesomeIcon icon={faSquareCheck} />}
                                        <span className="text-beside-svg">Unit Info</span>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    )
            })}
            <AddControlsInfoModal 
                readOnly={finalLock}
                product={addControlsToProduct} 
                serialNumbers={serial_numbers}
                toggle={() => { updateShowAddControlsInfoModal(!showAddControlsInfoModal); }} 
                onSuccess={()=>{ /* no op */}}
                isOpen={showAddControlsInfoModal}
            />
            {
                (updateQuantityProduct !== undefined) &&
                (<Modal isOpen={updateQuantityProduct !== undefined}
                    toggle={() => setUpdateQuantityProduct(undefined)}
                    autoFocus={false}>
                    <UpdateQuantityModal
                        setIsOpen={() => setUpdateQuantityProduct(undefined)}
                        product={updateQuantityProduct}
                        refetch={refetch}
                        key={updateQuantityProduct.id} />
                </Modal>)
            }
        </div>
    )
};

export default QuoteProduct;