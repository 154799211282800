import { Col, Row } from "reactstrap";
import { findDesignCoefficients, findMaxCFM } from "../helpers/fan-curve";
import { calculateAltitudeCorrectionFactor, calculateEfficiency, calculateFLA, calculateFanArrayWeight, calculateMCANew, calculateMOCPNew, calculateOperatingInputHorserpower, calculateOperatingRPM, calculateRPMSoundPower, calculateRedundancy, getCostBreakdown } from "../helpers/generate-configuration-query";
import parseMoney from "../helpers/money-utils";
import FanCurveChart from "./FanCurveChart";
import { RPMCoefficient } from "../helpers/file-checker";
import { Configuration, Control_Panel_Sizing, Cost, Product_Set_Input } from "../generated/graphql";
import { getPanelAll } from "../helpers/calculate-custom-controls";

export function ToolsConfigDisplay({
    product,
    configuration,
    fanCount,
    costs,
    allSizings,
}: {
    product: Product_Set_Input,
    configuration: Configuration,
    fanCount: number | null,
    costs?: Cost[],
    allSizings: Control_Panel_Sizing[],
}) {
    const altitudeCorrectionFactor = calculateAltitudeCorrectionFactor(product?.altitude);
    const backdraftDampersCorrectionValue = product?.backdraft_dampers ? 0.2 : 0;
    const adjustedTSP = (product?.total_static_pressure ?? 0) * altitudeCorrectionFactor + backdraftDampersCorrectionValue;

    const soundPrettyOutput = (value: any) => {
        if (!value || value < 0)
            return "-";
        return value
    }

    const cleanFanCount = fanCount ?? 0;
    const fla = calculateFLA(configuration?.fla_460, product.voltage_override, configuration?.min_v_fla, configuration?.max_v_fla);

    const rawCoefficients = !!configuration?.rmp_coefficients
        ? JSON.parse(configuration?.rmp_coefficients) 
        : null;

    let coefficients: RPMCoefficient[] = rawCoefficients
        ? Object.values(rawCoefficients)
        : [];

    const adjustedCoefficients = coefficients.map(c => Object.assign({}, c, {
        pressure: {
            a: c.pressure.a,
            b: c.pressure.b,
            c: c.pressure.c,
            d: (c.pressure.d / altitudeCorrectionFactor) - backdraftDampersCorrectionValue,
        }
    }));

    const cfm = product?.air_flow ?? 0;

    const designCoefficients = findDesignCoefficients({
        cfm,
        tsp: adjustedTSP,
        coefficients,
        fanCount: cleanFanCount,
        model: configuration.model
    });

    const horsepower = designCoefficients.upper.coefficient?.powerHp && designCoefficients.lower.coefficient?.powerHp ? calculateOperatingInputHorserpower(
        product.air_flow,
        designCoefficients.fanCount || 0,
        designCoefficients.lower.coefficient?.powerHp,
        designCoefficients.upper.coefficient?.powerHp,
        designCoefficients.interpolation
    ) : 0;
    const efficiency = calculateEfficiency({
        tsp: adjustedTSP,
        cfm,
        fanCount: cleanFanCount,
        horsepower,
    });

    const maxRPM = Math.max(...(Object.keys(rawCoefficients).map((k) => parseInt(k))));
    const maxRPMCoefficients = coefficients.find((c: RPMCoefficient) => c.RPM === maxRPM)
    const maxCFM = maxRPMCoefficients
        ? findMaxCFM({
            tsp: adjustedTSP,
            coefficient: maxRPMCoefficients.pressure,
        })
        : 0;

    const redundancy = calculateRedundancy({
        maxCFM,
        fanCount: cleanFanCount,
        designCFM: cfm,
    });

    const mca = calculateMCANew({ fla, fanCount: cleanFanCount, voltage: product.voltage_override ?? configuration.voltage, mas_ec_plus: product.mas_ec_plus });
    const mocp = calculateMOCPNew({ fla, fanCount: cleanFanCount, voltage: product.voltage_override ?? configuration.voltage, mas_ec_plus: product.mas_ec_plus });

    let controlPanelSizing = getPanelAll(fanCount, mocp, allSizings, product.mas_ec_plus);

    const systemWeight = calculateFanArrayWeight({
        fanCount: cleanFanCount,
        fanWeight: configuration.fan_weight,
        panelWeight: controlPanelSizing.sizing.weight, 
        bulkheadHeight: product.height || 0,
        bulkheadWidth: product.width || 0,
    });

    const operatingSpeed = designCoefficients.upper ? calculateOperatingRPM(
        designCoefficients.upper.rpm ?? 0,
        designCoefficients.upper.tsp ?? 0,
        adjustedTSP
    ) : 0;

    const soundRPM = !!designCoefficients.upper.coefficient && !!designCoefficients.lower.coefficient
        ? calculateRPMSoundPower(cleanFanCount, product.air_flow / cleanFanCount, designCoefficients.lower.coefficient, designCoefficients.upper.coefficient, designCoefficients.interpolation)
        : null;

    const unitCost = getCostBreakdown({
        fanCost: parseMoney(configuration?.price) ?? 0,
        fanCount: cleanFanCount ?? 1,
        controlPanelCost: controlPanelSizing.cost, 
        bulkheadHeight: product.height ?? 0,
        bulkheadWidth: product.width ?? 0,
        costPerSQFT:
            parseMoney(costs?.find((c) => c.name === "Bulkhead_Cost_per_SQFT")?.value) ?? 0,
        miscFlatCost:
            parseMoney(costs?.find((c) => c.name === "Misc_Flat")?.value) ?? 0,
        miscVariableCost:
            parseMoney(costs?.find((c) => c.name === "Misc_Variable:")?.value) ?? 0,
        hasTransducers: product.pressure_transducers ?? false,
        hasDampers: product.backdraft_dampers ?? false,
        damperCost:
            parseMoney(costs?.find((c) => c.name === "Backdraft_Damper")?.value) ?? 250,
        largeScalePricing: product.large_scale_pricing,
        discountedFanCost: product.discounted_fan_price
    })

    const StatRow = ({
        label,
        value,
        symbol,
        highlighted = false,
    }: { label: string, value: any, symbol: string, highlighted?: boolean }) => (
        <div className="stat-row-container">
            <div className="state-row-label">
                <span>{label}</span>
            </div>
            <div>
                <span className={highlighted ? "clipped-highlighted" : ""}>{value} {symbol}</span>
            </div>
        </div>
    );

    return <>
        <div>
            <div className="config-data">
                <Row className="mb-2">
                    <Col lg="6" md="6" sm="6">
                        <div className="stats-container">
                            <StatRow label="Fan Model" value={configuration.model} symbol='' />
                            <StatRow label="Fan Count" value={cleanFanCount} symbol='' />
                            <StatRow label="Efficiency" value={(efficiency * 100).toFixed(2)} symbol="%" />
                            <StatRow label="Redundancy" value={Math.round(Math.max(0.01, (redundancy * 100)))} symbol="%" />
                            <StatRow label="Motor Power" value={configuration.nominal_hp} symbol="HP" />
                            <StatRow label="Input Power" value={horsepower.toFixed(2)} symbol="HP" />
                            <StatRow label="Operating Speed" value={operatingSpeed} symbol="RPM" />
                            <StatRow label="Maximum Speed" value={maxRPM} symbol="RPM" />
                            <StatRow label="System Weight" value={Math.round(systemWeight)} symbol="Lbs" />
                            <StatRow label="Fan Weight" value={Math.round(configuration?.fan_weight ?? 0)} symbol="Lbs" />
                            <StatRow label="Control Panel Width" value={controlPanelSizing.sizing.width} symbol="IN" />
                            <StatRow label="Blade Diameter" value={configuration.blade_diameter} symbol="MM" />
                            <StatRow label="Blade Material" value={configuration.blade_material} symbol="" />
                            <StatRow label="Fan Depth" value={configuration.depth} symbol="IN" />
                            <StatRow label="Fan Manufacturer" value={configuration.manufacturer} symbol="" />
                            <StatRow label="Plate Dimensions" value={configuration.plate_dimensions} symbol="IN" />
                            { configuration.damper_id && <StatRow label="Damper ID" value={configuration.damper_id} symbol="IN" /> }
                        </div>
                    </Col>
                    <Col lg="6" md="6" sm="6" className="d-flex flex-column justify-content-between align-items-end">
                        <div style={{ height: '420px', width: '100%' }}>
                            <FanCurveChart
                                tsp={product?.total_static_pressure ?? 0}
                                cfm={cfm}
                                fanCount={cleanFanCount}
                                model={configuration.model}
                                coefficients={adjustedCoefficients}
                            />
                            <h3>Electrical Data</h3>
                            <div className="stats-container">
                                <StatRow label="FLA" value={+fla?.toFixed(2)} symbol="A" />
                                <StatRow label="MCA" value={mca?.toFixed(2)} symbol="A" />
                                <StatRow label="MOCP" value={Math.round(mocp)} symbol="A" />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col lg="6" md="6" sm="6">
                        <h3>Costs</h3>
                        <div className="stats-container">
                            <StatRow highlighted={!!product.discounted_fan_price} label="Fans" value={`$${Math.ceil(unitCost.totalFanCost).toLocaleString()}`} symbol='' />
                            <StatRow highlighted={product.large_scale_pricing} label="Freight (Fans)" value={`$${Math.ceil(unitCost.freightFanCost).toLocaleString()}`} symbol='' />
                            <StatRow label="Controls" value={`$${Math.ceil(unitCost.controlPanelCost).toLocaleString()}`} symbol='' />
                            <StatRow highlighted={product.large_scale_pricing} label="Freight (Controls)" value={`$${Math.ceil(unitCost.freightControlPanelCost).toLocaleString()}`} symbol='' />
                            <StatRow label="Bulkhead" value={`$${Math.ceil(unitCost.bulkheadCost).toLocaleString()}`} symbol='' />
                            <StatRow label="Misc Cost" value={`$${Math.ceil(unitCost.miscCost).toLocaleString()}`} symbol='' />
                            <StatRow label="Options Cost" value={`$${Math.ceil(unitCost.optionsCost).toLocaleString()}`} symbol='' />
                            <StatRow highlighted={product.large_scale_pricing} label="Total" value={`$${Math.ceil(unitCost.totalCost).toLocaleString()}`} symbol='' />
                        </div>
                    </Col>
                    <Col lg="6" md="6" sm="6">
                        <h3>Control Panel</h3>
                        <StatRow label="Amperage" value={Math.round(mocp)} symbol="A" />
                        <StatRow label="Depth" value={controlPanelSizing.sizing.depth} symbol="IN" />
                        <StatRow label="Height" value={controlPanelSizing.sizing.height} symbol="IN" />
                        <StatRow label="Width" value={controlPanelSizing.sizing.width} symbol="IN" />
                        <StatRow label="Weight" value={controlPanelSizing.sizing.weight} symbol="LBS" />
                        <StatRow label="Rating" value={(product.mas_ec_plus && !product.outdoor_rating) ? 'NEMA 1 (Indoor)' : 'NEMA 4 (Outdoor)'} symbol="" />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ overflowX: "auto" }}>
                        <h3>System Sound Power</h3>
                        <table className="sytem-sound-power-table">
                            <tr>
                                <th className="center">
                                    <div className="sytem-sound-power-table-header center">
                                        Type
                                    </div>
                                </th>
                                <th>
                                    <div className="sytem-sound-power-table-header">
                                        63 Hz
                                    </div>
                                </th>
                                <th>
                                    <div className="sytem-sound-power-table-header">
                                        125 Hz
                                    </div>
                                </th>
                                <th>
                                    <div className="sytem-sound-power-table-header">
                                        250 Hz
                                    </div>
                                </th>
                                <th>
                                    <div className="sytem-sound-power-table-header">
                                        500 Hz
                                    </div>
                                </th>
                                <th>
                                    <div className="sytem-sound-power-table-header">
                                        1,000 Hz
                                    </div>
                                </th>
                                <th>
                                    <div className="sytem-sound-power-table-header">
                                        2,000 Hz
                                    </div>
                                </th>
                                <th>
                                    <div className="sytem-sound-power-table-header">
                                        4,000 Hz
                                    </div>
                                </th>
                                <th>
                                    <div className="sytem-sound-power-table-header">
                                        8,000 Hz
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    <div className="sytem-sound-power-table-header type">
                                        Radiated
                                    </div>
                                </th>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {configuration.model === "N88-63314" ? "-" : soundPrettyOutput(soundRPM?.hz63.radiated.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz125.radiated.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz250.radiated.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz500.radiated.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz1000.radiated.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz2000.radiated.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz4000.radiated.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz8000.radiated.toFixed(0))}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div className="sytem-sound-power-table-header center">
                                        Unit Discharge
                                    </div>
                                </th>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {configuration.model === "N88-63314" ? "-" : soundPrettyOutput(soundRPM?.hz63.unitDischarge.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz125.unitDischarge.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz250.unitDischarge.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz500.unitDischarge.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz1000.unitDischarge.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz2000.unitDischarge.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz4000.unitDischarge.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz8000.unitDischarge.toFixed(0))}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div className="sytem-sound-power-table-header center">
                                        Unit Return
                                    </div>
                                </th>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {configuration.model === "N88-63314" ? "-" : soundPrettyOutput(soundRPM?.hz63.unitReturn.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz125.unitReturn.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz250.unitReturn.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz500.unitReturn.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz1000.unitReturn.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz2000.unitReturn.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz4000.unitReturn.toFixed(0))}
                                    </div>
                                </td>
                                <td>
                                    <div className="system-sound-power-table-data">
                                        {soundPrettyOutput(soundRPM?.hz8000.unitReturn.toFixed(0))}
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </Col>
                </Row>
            </div>
        </div>
    </>
}