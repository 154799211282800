import { useEffect, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';

import { useUpdateQuoteMutation } from '../generated/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

const TransmitModal = ({
    outerJobNumber,
    quoteId,
    operation,
    isOpen = true,
    isEditOpen = true,
    toggle,
    onSuccess
  }: {
    outerJobNumber: string,
    quoteId: number,
    operation: string,
    isOpen: boolean,
    isEditOpen: boolean,
    toggle: Function,
    onSuccess: Function,
  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [updateQuote] = useUpdateQuoteMutation();
    const [jobNumber, setJobNumber] = useState("");

    const validJobNumber = (jobNumber !== "");

    useEffect(() => { 
      setJobNumber("");
    }, [isOpen]);
    
    useEffect(() => { 
      setJobNumber(outerJobNumber ?? "");
    }, [isEditOpen, outerJobNumber]);
  
    const transmit = () => {
      setIsLoading(true);
      let setValues = {};
      if(operation === "transmit"){
        setValues["locked"] = true;
        setValues["job_number"] = jobNumber;
      } else {
        setValues["locked"] = false;
      }
      updateQuote({
        variables: {
          pk_columns: { id: quoteId },
          _set: setValues,
        },
        onCompleted: () => {
          // refetch();
          setIsLoading(false);
          isOpen = false;
          // toggle();
          onSuccess(operation, jobNumber);
        },
        onError: () => {
          setIsLoading(false);
        }
      });
    };

    const editJobNumber = () => {
      setIsLoading(true);
      updateQuote({
        variables: {
          pk_columns: { id: quoteId },
          _set: {job_number: jobNumber},
        },
        onCompleted: () => {
          setIsLoading(false);
          isEditOpen = false;
          onSuccess("edit");
        },
        onError: () => {
          setIsLoading(false);
        }
      });
    };
  
    return (<>
      <Modal isOpen={isOpen} toggle={() => toggle()} backdrop={"static"}>
            <div className="modal-header">
                <h3>{operation === "transmit" ? "Transmit Quote" : "Undo Transmit"}</h3>
                <FontAwesomeIcon
                style={{ fontSize: 20, cursor: "pointer", marginLeft: "5px" }}
                onClick={() => { if (!isLoading) toggle(); }}
                icon={faXmarkCircle} />
            </div>
            <ModalBody>
                { operation === "transmit" &&
                <>
                  <div>When you transmit this quote, it will:
                      <ul>
                        <li>Lock all products in each quote for this job and prevent editing</li>
                        <li>Generate serial numbers for each fan in the quote</li>
                        <li>Send an email to Benji Schau, Josh Suchy and Robert Tellander</li>
                      </ul>
                      When you transmit this quote, you will need to:
                      <ul>
                        <li>Set a job number for the transmitted job</li>
                        <li>Set unit information for each product in the selected quote</li>
                      </ul>
                      Once you have set unit information for each product:
                      <ul>
                        <li>A MAS model number will be generated for each product</li>
                        <li>You will be able to download the Ordering and Scheduling document CSVs</li>
                      </ul>
                      <strong>Please make sure you are sure before you transmit quote</strong>
                  </div>
                  <hr/>
                  <div className="box-label">Job number:</div>
                  <Input 
                    name="job_number" 
                    type="text"
                    value={jobNumber}
                    onChange={e => setJobNumber(e.target.value)}
                    invalid={!validJobNumber}
                  />
                </>
                }
                { operation === "untransmit" &&
                <div>
                  <strong>THIS IS A SERIOUS OPERATION<br/>Please make sure you are positive that you want to untransmit this job</strong><br/>
                  When you untransmit this quote, it will:<br/>
                  <ul>
                    <li>Send an email to Benji Schau, Josh Suchy and Robert Tellander to alert them</li>
                    <li>Unlock all products and quotes in this job and allow editing</li>
                    <li>Remove serial numbers and MAS model numbers from all products, and remove the job number from the job</li>
                    <li>Remove unit information set for each product</li>
                  </ul>
                </div>
                }
            </ModalBody>
            <ModalFooter className="justify-content-start mx-2">
                <Button 
                  disabled={isLoading || (operation !== "untransmit" && !validJobNumber)} 
                  type='submit' 
                  onClick={transmit}
                >
                {operation === "transmit" ? "Transmit Quote" : "Untransmit Quote"}
                </Button>
            </ModalFooter>
      </Modal>
      {/* The modal below is just for editing the job number, not really to be used anywhere else */}
      <Modal isOpen={isEditOpen} toggle={() => toggle()} backdrop={"static"}>
            <div className="modal-header">
                <h3>Edit Job Number</h3>
                <FontAwesomeIcon
                style={{ fontSize: 20, cursor: "pointer", marginLeft: "5px" }}
                onClick={() => { if (!isLoading) toggle(); }}
                icon={faXmarkCircle} />
            </div>
            <ModalBody>
            <div className="box-label">Enter new job number:</div>
                  <Input 
                    name="edit_job_number" 
                    type="text"
                    value={jobNumber}
                    onChange={e => setJobNumber(e.target.value)}
                    invalid={!validJobNumber}
                  />
            </ModalBody>
            <ModalFooter className="justify-content-start mx-2">
                <Button 
                  disabled={isLoading || !validJobNumber} 
                  type='submit' 
                  onClick={editJobNumber}
                >
                Update
                </Button>
            </ModalFooter>
      </Modal>
    </>);
  };
  
  export default TransmitModal;