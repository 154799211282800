import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, useState } from 'react';
import { 
        Configuration,
        Control_Panel_Sizing,
        Fan_Fit,
        Product, 
        Quote, 
        useGetControlPanelSizingQuery, 
        useGetCostsQuery, 
        useGetFanFitsQuery, 
        useGetFanModelFileLazyQuery, 
        useUpdateProductMutation,
        useGetMaxSerialQuery, 
        useClearSerialNumberMutation,
        Job,
        useUpdateQuoteMutation,
        useInsertManySerialNumbersMutation,
        Serial_Number_Insert_Input,
      } from '../generated/graphql';
import QuoteProduct from './QuoteProduct';
import { generateQuotePDF } from '../helpers/generate-pdf';
import { faCog, faFileInvoiceDollar, faFileZipper, faFileWord, faAdd, faFileArrowDown, faPenToSquare, faLockOpen, faXmarkCircle, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import AddProductModal from './AddProductModal';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useKeycloak } from "@react-keycloak/web";
import { generateQuoteDXF } from '../helpers/generate-quote.dxf';
import { toast } from 'react-toastify';
import generateQuoteDocx from '../helpers/generate-docx';
import { quoteSumTotal } from '../helpers/quote';
import UpdateQuoteSettings from './UpdateQuoteSettings';
import TransmitModal from './TransmitModal';
import controlsTeamExportHelpers from '../helpers/controls-team-export';
import AddFinalInfoModal from './AddFinalInfoModal';

function QuoteComponent({ quote,
  refetch,
  setDeleteQuoteId,
  setDuplicateQuote,
  job,
}: {
  quote: Quote;
  refetch: () => void;
  setDeleteQuoteId: any;
  setDuplicateQuote: any;
  job: Job;
}) {
  const [showAddProductModal, updateShowAddProductModal] = useState(false);
  const [showQuoteSettingsModal, updateShowQuoteSettingsModal] = useState(false);
  const [showTransmitModal, updateShowTransmitModal] = useState(false);
  const [showEditModal, updateShowEditModal] = useState(false);
  const [showFinalLockModal, updateShowFinalLockModal] = useState(false);
  const [showFinalInfoModal, updateShowFinalInfoModal] = useState(false);
  const { keycloak } = useKeycloak();
  const { data: costs } = useGetCostsQuery();
  const [getFanModelFile] = useGetFanModelFileLazyQuery();
  const { data: fanFitData } = useGetFanFitsQuery();
  const { data: sizing } = useGetControlPanelSizingQuery({});
  const { data: maxSerial, refetch: refetchMaxSerial } = useGetMaxSerialQuery();

  const requestDXF = async ({
    fileName,
    config,
    fanFitData,
    products,
    sizing,
  }: {
    fileName: string,
    config: Configuration,
    fanFitData?: Fan_Fit[]
    products: Product[],
    sizing: Control_Panel_Sizing[]
  }) => {
    let model = config.model;
    let getFMFRequest = await getFanModelFile({ variables: { model } });
    let fan_model_file = !!getFMFRequest.data?.fan_model_file.length
      ? getFMFRequest.data.fan_model_file[0]
      : undefined;

    generateQuoteDXF({
      config: config,
      fanFitData,
      fanModelFile: fan_model_file,
      fileName: fileName,
      products: products,
      sizings: sizing
    }).catch((e) => toast.error(e.message))
  };

  const [updateProduct] = useUpdateProductMutation();
  const [addManySerials] = useInsertManySerialNumbersMutation();
  const [clearSerial] = useClearSerialNumberMutation();

  const totalPrice = quoteSumTotal(
    quote.products,
    sizing?.control_panel_sizing,
    costs?.cost
  );

  const allProductsSelected = (quote.products.find(e => e.configuration === null) === undefined);
  const controlsInfoAllEntered = (quote.products.find(e => e.model_number === null) === undefined);

  const onTransmitSuccess = (jobNumber : string) => {
    // would rather not use magic number, but unsure how else to do it right now
    let curSerial : number = maxSerial.serial_number?.length ? maxSerial.serial_number[0].serial_number : controlsTeamExportHelpers.magicNumberSerialStarting;
    // Need to track unique info per products for email later
    let serialInputs : [Serial_Number_Insert_Input?] = [];
    let productAmts = {};
    let numberOfEcPlus = 0;
    for (let product of quote.products){
      // Track number of ec+ controls
      if(product.mas_ec_plus) numberOfEcPlus++;
      // Keep track of amounts per model number
      if (productAmts.hasOwnProperty(product.configuration.model)){
        productAmts[product.configuration.model] += (+product.configuration_quantity * +product.configured_fan_count);
      } else {
        productAmts[product.configuration.model] = (+product.configuration_quantity * +product.configured_fan_count);
      }
      for (let i = 0; i < product.configuration_quantity; i++){
        serialInputs.push({
          job_id: product.job_id,
          product_id: product.id,
          serial_number: ++curSerial,
        });
      }
    }

    // Add serial numbers
    addManySerials({
      variables: {
        objects: serialInputs
      },
      onCompleted: () => {/*noop*/
        console.log("Successfully added serial numbers");
        refetch();
      },
      onError: () => {/* maybe send me an email here? */
        console.log("Error adding serial numbers");
        controlsTeamExportHelpers.sendFailureEmail({jobName: job.name, quoteName: quote.name, errorType: "serialError" });
      }
    });

    let modelNumbers = "";
    let modelAmounts = "";
    for (const [key, value] of Object.entries(productAmts)){
      if (modelNumbers !== ""){
        modelNumbers += "!";
        modelAmounts += "!";
      } 
      modelNumbers += key.toString();
      modelAmounts += value.toString();
    }
    let controlsType = "Basic controls";
    if (numberOfEcPlus > 0 && numberOfEcPlus < quote.products.length) controlsType = "Basic and EC+ controls";
    else if (numberOfEcPlus === quote.products.length) controlsType = "EC+ controls";
    
    // Send emails
    controlsTeamExportHelpers.sendTransmitEmails({
      jobNumber : jobNumber, 
      jobName : job.name, 
      quoteName : quote.name, 
      jobId : quote.job_id.toString(),
      username: (keycloak.userInfo as any)?.name,
      modelNumbers: modelNumbers,
      modelAmounts: modelAmounts,
      controlsType: controlsType,
    });
  }

  const onUnTransmitSuccess = (jobNumber : string) => {
    for (let product of quote.products){
      updateProduct({
        variables: {
          pk_columns: { id: product.id },
          _set: { model_number : null },
        }
      })
      clearSerial({
        variables: {
          _eq: product.id
        },
        onCompleted: () => {/*noop*/},
        onError: () => {/* maybe send me an email here */}
      });
    }

    // Send emails
    controlsTeamExportHelpers.sendUnTransmitEmails({
      jobNumber : jobNumber, 
      jobName : job.name, 
      quoteName : quote.name, 
      jobId : quote.job_id.toString(),
    });
    refetchMaxSerial();
    refetch();
  }

  const containsUnfinishedProduct = quote.products.some((p: Product) => p.model_number === null);

  return (<div className="quote">
    <div className="quote-header">
      <div className="quote-header-title">
        {
          quote.locked &&
          <span className="job-number">
            {quote.job_number}
            {!quote.final_lock &&
              <FontAwesomeIcon icon={faPenToSquare} onClick={() => { updateShowEditModal(true); }}/>
            }
          </span>
        }
        <h4>{quote.name}</h4>
      </div>
      <div className="quote-header-buttons-holder">
        <div className="quote-header-buttons">
          <button className='add-icon' onClick={() => { updateShowAddProductModal(true); }} disabled={quote.locked}>
            {!quote.final_lock ? <><FontAwesomeIcon icon={faAdd} />Add Product</> : "Locked"}
          </button>
        </div>
        <div className="quote-header-buttons">
          <button className='add-icon' onClick={() => { updateShowQuoteSettingsModal(true); }} disabled={quote.locked}>
            <FontAwesomeIcon icon={faCog} />
          </button>
          <button className='add-icon' title='Download quote' onClick={() => {
            generateQuotePDF({
              jobName: job.name,
              user: keycloak.userInfo,
              quote,
              costs: costs?.cost,
              sizings: sizing?.control_panel_sizing,
            })
          }}>
            <FontAwesomeIcon icon={faFileInvoiceDollar} />
          </button>
          <button className={quote.products.find((p) => p.configuration === null) === undefined ? "add-icon" : "add-icon grayed-out"}  title='Download submittal' onClick={() => {
            generateQuoteDocx({
              jobName: job.name,
              fanFitData: fanFitData?.fan_fit,
              quote,
              products: quote.products,
              sizings: sizing?.control_panel_sizing,
            })
          }}>
            <FontAwesomeIcon icon={faFileWord} />
          </button>
          {quote.products && <button className='add-icon' title='Download drawings' onClick={() => {
            requestDXF({
              fileName: `${job.name} - ${quote.name} Drawings.zip`,
              config: quote.products[0].configuration!,
              fanFitData: fanFitData?.fan_fit,
              products: quote.products,
              sizing: sizing?.control_panel_sizing,
            })
          }}>
            <FontAwesomeIcon icon={faFileZipper} />
          </button>}
          <button
            onClick={() => setDuplicateQuote(quote)}
            disabled={quote.locked}
          >
            <FontAwesomeIcon icon="clone" />
          </button>
          <button
            onClick={() => setDeleteQuoteId(quote.id!)}
            disabled={quote.locked}
          >
            <FontAwesomeIcon icon="trash" />
          </button>
        </div>
      </div>
    </div>
    {
      quote.products.length > 0 ?
        <div>
          <QuoteProduct quote={quote} jobId={job.id} refetch={refetch} locked={quote.locked} finalLock={quote.final_lock}/>
          <div className="quote-footer">
            { !quote.final_lock && 
              <div className="quote-footer-transmit-button">
              { !quote.locked ? <button onClick={() => { updateShowTransmitModal(true); }} disabled={quote.products.length === 0 || !allProductsSelected}>Transmit</button> : <></>}
              { quote.locked ? <button className="untransmit-button"  onClick={() => { updateShowTransmitModal(true); }}>Untransmit</button> : <></>}
              </div>
            }
            <div className="quote-footer-buttons">
              { quote.locked && quote.final_lock 
                ? <button 
                    className="quote-footer-button"
                    onClick={() => {updateShowFinalInfoModal(true)}}
                  >
                    { quote.software && quote.firmware && <FontAwesomeIcon icon={faSquareCheck} />}
                    { (!quote.software || !quote.firmware) && <FontAwesomeIcon icon={faPenToSquare} />}
                    <span className="text-beside-svg">Order Info</span>
                  </button> 
                : <></>
              }
              <button 
                className="quote-footer-button" 
                hidden={!quote.locked} 
                disabled={!controlsInfoAllEntered}
                onClick={() => {
                  controlsTeamExportHelpers.generateOrderingCSV(
                    quote.products, 
                    job.name, 
                    quote.name, 
                    sizing.control_panel_sizing
                  )}
                }
              >
                  <FontAwesomeIcon icon={faFileArrowDown} />
                  <span className="text-beside-svg">Ordering</span>
              </button>
              <button 
                className="quote-footer-button" 
                hidden={!quote.locked} 
                disabled={!controlsInfoAllEntered}
                onClick={() => {
                  controlsTeamExportHelpers.generateScheduleInfoCSV(
                    quote.products, 
                    job.name, 
                    quote.name, 
                    quote.software, 
                    quote.firmware
                  )}
                }
              >
                  <FontAwesomeIcon icon={faFileArrowDown} />
                  <span className="text-beside-svg">Schedule</span>
              </button>
            </div>
            <div>
              Total: <strong>{totalPrice}</strong>
            </div>
          </div>
          { quote.locked && !quote.final_lock && !containsUnfinishedProduct &&
          <>
            <hr className="thin-hr"/>
            <button
              className="lock-quote-button"
              disabled={quote.final_lock}
              onClick={() => {updateShowFinalLockModal(true)}}
            >
              Lock Quote 
              <FontAwesomeIcon className="text-beside-svg" icon={faLockOpen} />
            </button>
          </>
          }
        </div>
        : <div className="empty-quote">
          <button onClick={() => { updateShowAddProductModal(true); }} disabled={quote.locked}>+ Add product</button>
        </div>
    }
    <ConfirmFinalLockModal
      isOpen={showFinalLockModal} 
      quoteId={quote.id} 
      closeModal={() => {updateShowFinalLockModal(false)}} 
      refetch={undefined}
    />
    <AddProductModal
      isOpen={showAddProductModal}
      jobId={job.id}
      quoteId={quote.id}
      toggle={() => { updateShowAddProductModal(!showAddProductModal); }}
      key={quote.id}
      onSuccess={() => {
        updateShowAddProductModal(false);
        refetch();
      }}
    />
    <TransmitModal
      isOpen = {showTransmitModal}
      isEditOpen = {showEditModal}
      outerJobNumber={quote.job_number}
      quoteId={quote.id}
      operation={quote.locked ? "untransmit" : "transmit"}
      toggle={() => {
                if (showTransmitModal) updateShowTransmitModal(!showTransmitModal);
                if (showEditModal) updateShowEditModal(!showEditModal);
              }}
      onSuccess={(operation : string, jobNumber: string) => {
        if(operation === "edit") {
          updateShowEditModal(false);
        } else if(operation === "transmit"){
          onTransmitSuccess(jobNumber);
          updateShowTransmitModal(false);
        } else {
          onUnTransmitSuccess(jobNumber);
          updateShowTransmitModal(false);
        }
        refetch();
      }}
    />
    <AddFinalInfoModal 
      quote={quote}
      isOpen={showFinalInfoModal}
      toggle={() => {updateShowFinalInfoModal(!showFinalInfoModal);}}
      onSuccess={() => {updateShowFinalInfoModal(false); refetch();}}
    />
    {
      showQuoteSettingsModal &&
      (<Modal isOpen={showQuoteSettingsModal}
        toggle={() => updateShowQuoteSettingsModal(false)}
        autoFocus={false}>
        <UpdateQuoteSettings
          setIsOpen={() => updateShowQuoteSettingsModal(false)}
          quote={quote}
          costs={costs?.cost}
          sizings={sizing?.control_panel_sizing}
          refetch={refetch}
          key={quote.id} />
      </Modal>)
    }
  </div>);
}

const ConfirmFinalLockModal = ({
  quoteId,
  closeModal,
  isOpen,
  refetch
}: {
  quoteId: number,
  closeModal: Function,
  isOpen: boolean,
  refetch: Function
}) => {
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [updateQuote] = useUpdateQuoteMutation();

  const changeConfirmed = (e : ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) setConfirmed(true);
    else setConfirmed(false);
  }

  const lockQuote = () => {
    if (!confirmed || loading) return;
    setLoading(true);
    updateQuote({
      variables: {
        pk_columns: { id: quoteId },
        _set: { final_lock : true },
      },
      onCompleted: () => {
        refetch();
        setLoading(false);
        closeModal();
      },
      onError: () => {
        setLoading(false);
      }
    });
  }

  return (
    <Modal isOpen={isOpen} backdrop={"static"}>
      <div>
        <div className="modal-header">
          <h3>Lock Quote</h3>
          <FontAwesomeIcon
            style={{ fontSize: 20, cursor: "pointer", marginLeft: "5px" }}
            onClick={() => { if (!loading) closeModal(); }}
            icon={faXmarkCircle} />
        </div>
        <ModalBody>
          <div className="d-flex flex-column mb-3">
            Once you have locked this quote, you will still be able to generate all files, 
            but you will not be able to perform any changes to any products or to the quote itself.
            <strong>This operation is not reversible.</strong>
            <div className="confirm-holder">
              <input id="finalLockConfirm" type="checkbox" checked={confirmed} onChange={changeConfirmed}/>
              <label className="box-label" htmlFor="finalLockConfirm">Yes, I am sure I want to permanently lock this quote.</label>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-start mx-2">
          <button className="lock-button cancel-lock" disabled={loading} onClick={()=>{closeModal();}}>Cancel</button>
          <button className="lock-button confirm-lock" disabled={loading || !confirmed} onClick={()=>{lockQuote(); closeModal();}}>Confirm</button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default QuoteComponent;