/* eslint-disable */
import { Config } from "@testing-library/react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Button, FormFeedback, Input, Spinner } from "reactstrap";
import { validateAirflow, validateHeight, validatePressure, validateWidth } from "../helpers/product";
import saveAs from "file-saver";
import jszip from "jszip";
import { getDrawing } from "./flat_patterns";
import { Knowledgebase } from "@mashvac/daedex";
import { Configuration, useGetFanModelsQuery } from "../generated/graphql";

const KB = new Knowledgebase();

export default function Flats() {
    const [productDetails, setProductDetails] = useState<any>({
        fan_count: 9,
        fan_model: "N88-45316",
        width: 120,
        height: 112,
        dampers: false
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(undefined);
    const [touchedWidth, setTouchedWidth] = useState(false);
    const [touchedHeight, setTouchedHeight] = useState(false);
    const [flats, setFlats] = useState<any>();
    const [panels, setPanels] = useState<any>();
    const [hasSearched, setHasSearched] = useState(false);
    const {data: fanModels} = useGetFanModelsQuery();

    const validWidth = !touchedWidth || validateWidth(productDetails.width);
    const validHeight = !touchedHeight || validateHeight(productDetails.height);

    const canvasRef = useRef(null);

    const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setFlats(undefined);
        setError(undefined);
        try {
            const flats = await KB.flatPattern({
                model: productDetails.fan_model,
                bulkheadWidth: productDetails.width,
                bulkheadHeight: productDetails.height,
                fans: productDetails.fan_count,
                dampers: productDetails.dampers
            });
            setFlats(flats);
            const p = {}
            flats.layout.map(l => flats.panels[l]).forEach(panel => {
                if (p[panel.type]) {
                    p[panel.type]["count"] += 1;
                } else {
                    p[panel.type] = {
                        count: 1,
                        width: panel.core_width
                    }
                }
            });
            setPanels(p);
        } catch (e) {
            setError(e);
        }
        setLoading(false);
    }

    const downloadFlats = async () => {
        var zip = new jszip();

        const typesProcessed = {};
        if (flats && flats.panels) {
            let numFanPanelTypes = 0;
            flats.panels.forEach((panel : any) => {
                if(panel.type === "fan") numFanPanelTypes++;
            });
            let fanPanelTypeCount = 1;
            flats.panels.forEach((panel: any) => {
                let fileName = panel.type;
                if (panel.type === "fan" && numFanPanelTypes > 1) fileName += ` (${fanPanelTypeCount++})`;
                if (panel.type === "side" && panel.channels.indexOf("right") < 0)
                    return;
                zip.file(`${fileName}.dxf`, getDrawing(panel).toDxfString())
            })
        }
        zip.generateAsync({ type: "blob" })
            .then(function (content) {
                saveAs(content, "flats.zip");
            });
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value: any = "";

        if (e.target.type === "checkbox") {
            value = e.target.checked;
        } else if (e.target.type === "number") {
            try {
                value = parseFloat(e.target.value);
            } catch (e) {
                return;
            }
        } else {
            value = e.target.value;
        }
        setProductDetails({ ...productDetails, [e.target.name]: value });
    }

    useEffect(() => {
        if (!flats)
            return;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        const panels = flats.layout.map(pos => flats.panels[pos]).map(panel => ({
            core_width: panel.core_width,
            height: panel.height,
            fans: panel.elements.filter(el => el.type === "fan")
        }))

        let width = 0;
        const height = panels[0].height;

        context.strokeStyle = '#FF0000';
        context.lineWidth = 1;

        const drawingDims = {
            width: 620,
            height: 620
        }
        const dimensionsLineHeight = 30;

        canvas.width = drawingDims.width + dimensionsLineHeight;
        canvas.height = drawingDims.height + 2 * dimensionsLineHeight;

        panels.forEach(panel => {
            width += panel.core_width;
        });

        let scale = 1;

        if (width > height) {
            scale = drawingDims.width / width;
        } else {
            scale = drawingDims.height / height;
        }

        let idx = 0;
        let x = (drawingDims.width - width * scale) / 2 + 1;
        let startX = x;
        panels.forEach(panel => {
            const rectWidth = panel.core_width * scale;
            const rectHeight = panel.height * scale;
            const rectX = x;
            const rectY = 20 + (drawingDims.height - rectHeight) / 2;
            context.strokeRect(rectX, rectY, rectWidth, rectHeight);
            if (panel.fans) {
                panel.fans.forEach(fan => {
                    const fanSize = fan.width * scale;
                    context.strokeRect(x + (rectWidth - fanSize) / 2, (fan.y / panel.height) * rectHeight + rectY - scale * fan.height / 2, fanSize, fanSize);
                })
            }
            context.font = "13px sans-serif";
            context.textAlign = "center";
            context.fillText(`${panel.core_width}"`, rectX + rectWidth / 2, rectHeight + rectY + dimensionsLineHeight / 2);
            
            if(idx === 0 || idx === (panels.length - 1)) context.fillText("Side", rectX + rectWidth / 2, 15);
            else {
                if (panel.fans.length > 0) context.fillText("Fan", rectX + rectWidth / 2, 15);
                else context.fillText("Filler", rectX + rectWidth / 2, 15);
            }
            
            x += rectWidth - 0.5;
            idx++;
        });

        // context.fillText("");

        // context.fillText(`${Math.round(width * 10) / 10}"`, 0 + (width * scale) / 2, ((drawingDims.height - height * scale) / 2 + height * scale + dimensionsLineHeight) + dimensionsLineHeight / 2);
        context.fillText(`${Math.round(width * 10) / 10}"`, (startX + x) / 2, 10 + ((drawingDims.height - height * scale) / 2 + height * scale + dimensionsLineHeight) + dimensionsLineHeight / 2);
        context.save();
        context.translate(0, 0);
        context.rotate(Math.PI / 2);
        // context.fillText(`${Math.round(height * 10) / 10}"`, (height * scale) / 2 + (drawingDims.height - height * scale) / 2, -(canvas.width - dimensionsLineHeight + 5));
        context.fillText(`${Math.round(height * 10) / 10}"`, 20 + (height * scale) / 2 + (drawingDims.height - height * scale) / 2, -(x + 10));
        context.restore();
    }, [flats]);

    return (<div className="tools-content">
        <form onSubmit={onSubmit}>
            <div>
                <div className="box-label">Fan Model</div>
                <input
                    name="fan_model"
                    type="text"
                    value={productDetails.fan_model}
                    list="model_list"
                    onChange={handleChange}
                />
                <datalist id="model_list">
                    {
                        (fanModels?.configuration || []).map((model : Configuration) => {
                            return(<option key={model.model} value={model.model}/>);
                        })
                    }
                </datalist>
            </div>
            <div>
                <div className="box-label">Fan Count</div>
                <Input
                    name="fan_count"
                    type="number"
                    value={productDetails.fan_count}
                    onChange={handleChange}
                    invalid={productDetails.fan_count < 1}
                />
            </div>
            <div>
                <div className="box-label">Width</div>
                <Input
                    name="width"
                    type="number"
                    value={productDetails.width}
                    onChange={handleChange}
                    invalid={!validWidth}
                    onBlur={() => setTouchedWidth(true)}
                />
                <FormFeedback>1 decimal place only</FormFeedback>
            </div>
            <div>
                <div className="box-label">Height</div>
                <Input
                    name="height"
                    type="number"
                    value={productDetails.height}
                    onChange={handleChange}
                    invalid={!validHeight}
                    onBlur={() => setTouchedHeight(true)}
                />
                <FormFeedback>1 decimal place only</FormFeedback>
            </div>
            <div>
                <label>
                    <input type="checkbox" value={productDetails.dampers} name="dampers" onChange={handleChange} style={{ marginRight: 5 }} />
                    Dampers
                </label>
            </div>
            <button type="submit">Submit</button>
        </form>
        <div className="results">
            {loading && <div className="loading">
                <Spinner />
            </div>}
            {!loading && !hasSearched && <div />}
            {!loading && !flats && hasSearched && <div className="no-results">
                No results found
            </div>}
            {!loading && error && (<div>
                <h3>Error</h3>
                <pre>{error.message}</pre>
            </div>)}
            {!loading && !!flats && <div className="flats-result">
                <div className="flats-result">
                    {panels && Object.keys(panels).length > 0 && (
                        <div className="flats-data">
                            <div className="flats-data-inset">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Panel</th>
                                            <th>Count</th>
                                            <th>Width</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.keys(panels).map(panel => <tr>
                                                <td>{panel}</td>
                                                <td className="centered">{panels[panel].count}</td>
                                                <td className="centered">{panels[panel].width}</td>
                                            </tr>)
                                        }
                                    </tbody>
                                </table>
                                <table className="flange-depth">
                                    <tbody>
                                        <td><strong>Flange Depth</strong></td>
                                        <td>{flats.panels[0].height > 60 ? "3" : "2"}"</td>
                                    </tbody>
                                </table>
                                <Button onClick={downloadFlats}>Download flats (.zip)</Button>
                            </div>
                            <div className="flats-flange-image">
                                <div className="flats-side-label">{flats.panels[0].height > 60 ? "3" : "2"}"</div>
                                <div><img src={require("../assets/FlatsFlanges.jpg")}/></div>
                                <div className="flats-side-label">{flats.panels[0].height > 60 ? "3" : "2"}"</div>
                            </div>
                        </div>)}
                    <div className="flats-display">
                        <canvas ref={canvasRef} />
                    </div>
                </div>
            </div>}
        </div>
    </div>)
};